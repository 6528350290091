import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.less'  //重置样式

createApp(App).use(store).use(router).mount('#app')


//跳转后回滚顶部
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
});

