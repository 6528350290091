import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    //首页
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home/index.vue'),
  },
  {
    //我的账单
    path: '/mybills',
    name: 'Mybills',
    component: () => import('../views/MyBills/index.vue'),
  },
  {
    //订单详情
    path: '/billingdetails',
    name: 'Billingdetails',
    component: () => import('../views/MyBills/BillingDetails/index.vue'),
  },

  {
    //加油教程
    path: '/oilcourse',
    name: 'Oilcourse',
    component: () => import('../views/OilCourse/index.vue'),

  },
  {
    //加油操作
    path: '/refuelingoperation',
    name: 'Refuelingoperation',
    component: () => import('../views/RefuelingOperation/index.vue'),
  },
  {
    //支付
    path: '/paypage',
    name: 'Paypage',
    component: () => import('../views/PayPage/index.vue'),
  },
  {
    //活动详情
    path: '/activeDetail',
    name: 'ActiveDetail',
    component: () => import('../views/ActiveDetail/index.vue'),
  },
  {
    //空白页
    path: '/blank',
    name: 'Blank',
    component: () => import('../views/Blank/index.vue'),
  },
  //如果是没有的页面，就跳转到首页
  {    
    path: '/:catchAll(.*)',
    redirect: '/home', 
    hidden: true 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
