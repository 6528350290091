import { createStore } from 'vuex'

export default createStore({
  state: {
    payUrl:'',
    tengxunMobileUrl:'',
    tengxunH5Url:'',
    gaodeH5UrlL:'',
    baiduUrl:'',
    baiduUrlH5:'',
    baiduUrlAndroid:''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
